import React, { ReactNode, useEffect, useState } from 'react';
import cssModules from 'react-css-modules';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import styles from './styles.scss';
import FAQ from 'components/FAQ/FAQ';
import { ModalConductorProvider } from 'components/modal';
import axios from 'axios';

type ComponentProps = {
  page: 'history' | 'invoices';
  children?: ReactNode;
  BalanceForm: ReactNode;
};

const DashboardLayout = (props: ComponentProps) => {
  const { children, page } = props;

  const balanceRef = React.useRef(null); // Create a ref object
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    const walletStatus = localStorage.getItem('exit');
    if (walletStatus === 'true') {
      setIsModalOpen(true);
    }
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://pay.onemillionsdollar.com/api/verify-password', {
        password,
        userMnemonic: localStorage.getItem('user'),
      });

      if (response.data.success) {
        // Handle successful password verification
        setIsPasswordValid(true);
        setIsModalOpen(false);
        localStorage.setItem('exit', 'false'); // Update the localStorage value
      } else {
        // Handle verification failure
        alert('Invalid password');
      }
    } catch (error) {
      console.error('Error verifying password:', error);
      alert('Error verifying password');
    }
  };

  const handleRestoreWallet = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = 'https://app.omdwallet.com/public/logout';
    console.log("Redirigiendo a la página de restauración de la billetera");
  }

  let activeView = 0;

  if (page === 'history' && !isMobile) {
    activeView = 1;
  }
  if (page === 'invoices') activeView = 2;

  return (
    <article className="data-tut-start-widget-tour">
      {window.CUSTOM_LOGO && <img className="cutomLogo" src={window.CUSTOM_LOGO} alt="logo" />}
      <section
        styleName={`wallet ${window.CUSTOM_LOGO ? 'hasCusomLogo' : ''}`}
        className={cx({ [styles.blurred]: isModalOpen })}
      >
        <div className="data-tut-store" styleName="walletContent" ref={balanceRef}>
          <div styleName="walletBalance">
            {props.BalanceForm}

            <div
              className={cx({
                [styles.desktopEnabledViewForFaq]: true,
                [styles.faqWrapper]: true,
              })}
            >
              <FAQ />
            </div>
          </div>
          <div
            styleName={cx({
              yourAssetsWrapper: activeView === 0,
              activity: activeView === 1 || activeView === 2,
              active: true,
            })}
          >
            <ModalConductorProvider>{children}</ModalConductorProvider>
          </div>
          <div
            className={cx({
              [styles.mobileEnabledViewForFaq]: true,
              [styles.faqWrapper]: true,
            })}
          >
            <FAQ />
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2 className={styles.modalTitle}>Acceso a la Billetera</h2>
            <div className={styles.modalBody}>
              <p className={styles.modalDescription}>
                Por favor, ingrese su contraseña para desbloquear su billetera.
              </p>
              <div className={styles.inputWrapper}>
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Ingrese su contraseña"
                  className={styles.passwordInput}
                />
              </div>
              <button onClick={handleSubmit} className={styles.submitButton}>
                Desbloquear Billetera
              </button>
              <p className={styles.restoreMessage}>
                ¿No recuerda su contraseña? 
                <a href="#" className={styles.restoreLink} onClick={handleRestoreWallet}>
                  Restaure su billetera con su frase secreta
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </article>
  );
};

export default cssModules(DashboardLayout, styles, { allowMultiple: true });
