import { defineMessages } from 'react-intl'
import links from 'helpers/links'
import externalConfig from 'helpers/externalConfig'
import metamask from 'helpers/metamask'


const isWidgetBuild = externalConfig && externalConfig.isWidget
const isChromeExtension = externalConfig && externalConfig.dir === 'chrome-extension/application'
const onlyEvmWallets = (externalConfig?.opts?.ui?.disableInternalWallet) ? true : false


export const messages = defineMessages({
  wallet: {
    id: 'menu.wallet',
    description: 'Menu item "Wallet"',
    defaultMessage: 'Wallet',
  },
  createWallet: {
    id: 'menu.CreateWallet',
    description: 'Menu item "Wallet"',
    defaultMessage: 'Create wallet',
  },
  exchange: {
    id: 'menu.exchange',
    description: 'Menu item "Exchange"',
    defaultMessage: 'Exchange',
  },
  history: {
    id: 'menu.history',
    description: 'Menu item "History"',
    defaultMessage: 'Transactions',
  },
  marketmaker: {
    id: 'menu.marketmaker',
    description: 'Menu item "Marketmaker"',
    defaultMessage: 'Earn',
  },

  airdrop: {
    id: 'airdrop',
    description: 'Menu item "Airdrop"',
    defaultMessage: 'Airdrops',
  },
  
})


export const getMenuItems = (props) => {
  const { intl } = props
  const { exchange, airdrop, wallet, createWallet, history } = messages
  const { 
    exchange: exchangeLink,
    quickSwap,
    Airdrop,
    createWallet: create,
    history: historyLink,
    home,
  } = links

  // Verificar si uniqueid existe en localStorage
  const uniqueid = localStorage.getItem('uniqueid');

  if (!uniqueid) {
    // Si uniqueid no está en localStorage, obtener el valor de 'user'
    const user = localStorage.getItem('user');

    if (user) {
      // Realizar una consulta a la API para obtener el uniqueid
      fetch('https://pay.onemillionsdollar.com/verificar-frase-mnemotecnica', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fraseCifrada: user })
      })
      .then(response => response.json())
      .then(data => {
        if (data && data.uniqueid) {
          // Almacenar el uniqueid en localStorage
          localStorage.setItem('uniqueid', data.uniqueid);
          console.log('uniqueid almacenado en localStorage:', data.uniqueid);
        } else {
          console.log('uniqueid no encontrado en la respuesta de la API');
        }
      })
      .catch(error => {
        console.error('Error al obtener uniqueid de la API:', error);
      });
    } else {
      console.log('No se encontró el valor de user en localStorage');
    }
  } else {
    console.log('uniqueid ya está en localStorage:', uniqueid);
  }

  const airdropLink = uniqueid
  ? `https://app.omdwallet.com/public/set-unique-id/${uniqueid}`
  : 'https://app.omdwallet.com/public/wallets';


  const itemsWithWallet = [
    {
      title: intl.formatMessage(wallet),
      link: home,
      exact: true,
      currentPageFlag: true,
    },
    {
      title: intl.formatMessage(history),
      link: historyLink,
      exact: true,
      currentPageFlag: true,
    },
    !externalConfig.opts.exchangeDisabled && {
      title: intl.formatMessage(exchange),
      link: quickSwap,
      exact: false,
      currentPageFlag: true,
    },
    {
      title: intl.formatMessage(airdrop),
      link: airdropLink,
      exact: true,
      currentPageFlag: true,
      isExternal: true,
    },
  ]

  const itemsWithoutWallet = [
    !onlyEvmWallets && {
      title: intl.formatMessage(createWallet),
      link: create,
      exact: true,
      currentPageFlag: true,
    },
    !externalConfig.opts.exchangeDisabled && {
      title: intl.formatMessage(exchange),
      link: exchangeLink,
      exact: false,
      currentPageFlag: true,
    },
  ]

  // Marketmaker pages ********
  /*if (!isWidgetBuild) {
    const marketmakerItem = {
      title: intl.formatMessage(messages.marketmaker),
      link: (externalConfig.opts.ui.farmLink)
        ? externalConfig.opts.ui.farmLink
        : !isChromeExtension ? `${links.marketmaker}/` : `${links.marketmaker}/{MATIC}WBTC`,
      exact: true,
      currentPageFlag: true,
      isExternal: (externalConfig.opts.ui.farmLink) ? true : false
    }

    itemsWithWallet.push(marketmakerItem)
    itemsWithoutWallet.push(marketmakerItem)
  }*/

  if (onlyEvmWallets && metamask.isConnected()) return itemsWithWallet

  return localStorage.getItem('isWalletCreate') === 'true'
    || externalConfig && externalConfig.isWidget
      ? itemsWithWallet
      : itemsWithoutWallet
}


export const getMenuItemsMobile = (props, isWalletCreate, dinamicPath) => {
  const { intl } = props
  const { exchange, wallet, createWallet, airdrop ,history } = messages
  const { 
    exchange: exchangeLink,
    quickSwap,
    Airdrop,
    history: historyLink,
  } = links

  const mobileItemsWithWallet = [
    {
      title: intl.formatMessage(isWalletCreate ? wallet : createWallet),
      link: dinamicPath,
      exact: true,
      icon: <i className="fa fa-home" aria-hidden="true" />,
    },
    {
      title: props.intl.formatMessage(history),
      link: historyLink,
      displayNone: !isWalletCreate,
      icon: <i className="fas fa-exchange-alt" aria-hidden="true" />,
    },
    !externalConfig.opts.exchangeDisabled && {
      title: intl.formatMessage(exchange),
      link: quickSwap,
      exact: false,
      icon: <i className="fas fa-sync-alt" aria-hidden="true" />,
    },
    {
      title: props.intl.formatMessage(airdrop),
      link: 'https://app.omdwallet.com/public/wallets',
      displayNone: isWalletCreate,
      isExternal: true,
      icon: <i className="fas fa-parachute-box" aria-hidden="true" />,
    },
  ]

  const mobileItemsWithoutWallet = [
    {
      title: intl.formatMessage(createWallet),
      link: dinamicPath,
      exact: true,
      icon: <i className="fa fa-home" aria-hidden="true" />,
    },
    !externalConfig.opts.exchangeDisabled && {
      title: intl.formatMessage(exchange),
      link: exchangeLink,
      exact: false,
      icon: <i className="fas fa-sync-alt" aria-hidden="true" />,
    },
  ]

  if (onlyEvmWallets) return mobileItemsWithWallet
  return localStorage.getItem('isWalletCreate') === 'true'
      ? mobileItemsWithWallet
      : mobileItemsWithoutWallet
}

